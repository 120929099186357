import { httpClient_get, httpClient_post } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';

function deleteScenarioService(roundScenarioId) {
  return httpClient_post(
    `/participant/stratedge/deletescenario?round_scenario_id==${roundScenarioId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function sendDecisionService(roundScenarioId) {
  return httpClient_post(
    `/participant/stratedge/senddecision?round_scenario_id=${roundScenarioId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getStratEdgeConfigurationService(gameConfigurationId) {
  return httpClient_get(
    `/moderator/stratedge/getconfiguration?game_configuration_id=${gameConfigurationId}`,
  )
    .then((response) => {
      return response.data;
    })


    .catch((error) => {
      throw error;
    });
}

function getScenariosService(gameSessionId, roundId) {
  return httpClient_get(
    `/participant/stratedge/getscenarios?game_session_id=${gameSessionId}&round_id=${roundId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function initSimulationService(configParticipant, resultParticipant) {

  try {
    const response = [];
    const competitorParams = [];
    const {
      competitors,
      strategicDecisions,
      team_competitors,
    } = configParticipant;

    const currentRoundId = Math.max.apply(
      Math,
      resultParticipant.round_scenarios.map(function (o) {
        return o.round_id;
      }),
    );
    const roundScenarios = resultParticipant.round_scenarios.find(
      (rs) => rs.round_id === currentRoundId,
    );


    for (const competitor of competitors) {
      const obj = {};
      obj.team_id = team_competitors.find(
        (tc) => competitor.id === tc.competitor_id,
      ).team_id;
      obj.competitor_fixed_name = competitor.fixed_name;
      obj.strategicDecisions = [];
      for (const d of strategicDecisions) {
        obj.strategicDecisions.push({
          type: d.type,
          id: null,
        });
      }
      response.push(obj);

      if (roundScenarios) {
        const scenarioResult = roundScenarios.scenario_results.find(
          (sr) => sr.team_id === obj.team_id,
        );
        if (scenarioResult) {
          competitorParams.push({
            id: competitor.id,
            fixed_name: competitor.fixed_name,
            production_cost: scenarioResult.cost,
            production_capacity: scenarioResult.capacity,
            fret_market1: scenarioResult.market_results[0].fret,
            fret_market2: scenarioResult.market_results[1].fret,
            budget: scenarioResult.reserve.toFixed(2),
          });
        } else {
          competitorParams.push({
            id: competitor.id,
            fixed_name: competitor.fixed_name,
            production_cost: competitor.production_cost,
            production_capacity: competitor.production_capacity,
            fret_market1: competitor.fret_market1,
            fret_market2: competitor.fret_market2,
            budget: configParticipant.initial_budget,
          });
        }
      } else {
        competitorParams.push({
          id: competitor.id,
          fixed_name: competitor.fixed_name,
          production_cost: competitor.production_cost,
          production_capacity: competitor.production_capacity,
          fret_market1: competitor.fret_market1,
          fret_market2: competitor.fret_market2,
          budget: configParticipant.initial_budget,
        });
      }
    }

    return { response, competitorParams };
  } catch (error) {
    console.log("error", error)
    throw error;
  }
}

function chooseDecisionService(decision, competitorParams, add) {
  try {
    if (add) {
      competitorParams.budget -= decision.price;

      competitorParams.production_cost =
        competitorParams.production_cost * (1 + decision.cost_impact);
      competitorParams.production_cost = competitorParams.production_cost.toFixed(
        2,
      );

      competitorParams.production_capacity =
        competitorParams.production_capacity * (1 + decision.capacity_impact);
      competitorParams.production_capacity = competitorParams.production_capacity.toFixed(
        2,
      );

      competitorParams.fret_market1 =
        competitorParams.fret_market1 * (1 + decision.fret_impact_market1);
      competitorParams.fret_market1 = competitorParams.fret_market1.toFixed(2);

      competitorParams.fret_market2 =
        competitorParams.fret_market2 * (1 + decision.fret_impact_market2);
      competitorParams.fret_market2 = competitorParams.fret_market2.toFixed(2);
    } else {
      competitorParams.budget += decision.price;

      competitorParams.production_cost =
        competitorParams.production_cost / (1 + decision.cost_impact);
      competitorParams.production_cost = competitorParams.production_cost.toFixed(
        2,
      );

      competitorParams.production_capacity =
        competitorParams.production_capacity / (1 + decision.capacity_impact);
      competitorParams.production_capacity = competitorParams.production_capacity.toFixed(
        2,
      );

      competitorParams.fret_market1 =
        competitorParams.fret_market1 / (1 + decision.fret_impact_market1);
      competitorParams.fret_market1 = competitorParams.fret_market1.toFixed(2);

      competitorParams.fret_market2 =
        competitorParams.fret_market2 / (1 + decision.fret_impact_market2);
      competitorParams.fret_market2 = competitorParams.fret_market2.toFixed(2);
    }

    return competitorParams;
  } catch (error) {
    //console.log('lllllllllllllllllll', error);
    throw error;
  }
}

function getStratEdgeConfigurationParticipantService(gameSessionId) {
  return httpClient_get(
    `/participant/stratedge/getconfiguration?game_session_id=${gameSessionId}`,
    //${gameConfigurationId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getStratEdgeGameSessionConfigurationService(gameSessionId) {
  return httpClient_get(
    `/moderator/stratedge/getgamesessionconfiguration?game_session_id=${gameSessionId}`,
    //${gameConfigurationId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function startOrStopRoundService(roundId, status) {
  return httpClient_post(
    `/moderator/stratedge/startorstopround?round_id=${roundId}&status=${status}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function submittedDecisionsService(roundId) {
  return httpClient_get(
    `/moderator/stratedge/submitteddecisions?round_id=${roundId}`,
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function resetRoundService(roundId) {
  return httpClient_post(
    `/moderator/stratedge/resetround?round_id=${roundId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}


function saveScenarioService(scenario) {
  return httpClient_post(`/participant/stratedge/savescenario`, scenario)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function runSimulationService(simulation) {
  const data = {};
  data.round_id = simulation.roundId;
  data.game_session_id = simulation.gameSessionId;
  data.is_decision = simulation.isDecision;
  data.team_scenarios = [];
  data.competitor_fixed_name = simulation.teamScenarios.competitor_fixed_name


  for (const team of simulation.teamScenarios) {
    const teamData = {};
    teamData.team_id = team.team_id;
    teamData.competitor_fixed_name = team.competitor_fixed_name;
    teamData.strategic_decisions = [];

    for (const decision of team.strategicDecisions) {
      if (decision.id !== null) {
        teamData.strategic_decisions.push({ id: decision.id });
      }
    }
    data.team_scenarios.push(teamData);
  }

  return httpClient_post(`/participant/stratedge/launchsimulation`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getStratEdgeResultService(gameSessionId) {
  return httpClient_get(
    `/moderator/stratedge/getresults?game_session_id=${gameSessionId}`,
  )
    .then((response) => {
      if (response.data.round_scenarios.length > 0) {
        response.data.round_scenarios.sort(function (a, b) {
          return a.round_id - b.round_id;
        });
      }
      if (response.data.rounds.length > 0) {
        response.data.rounds.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function getStratEdgeResultParticipantService(gameSessionId) {
  return httpClient_get(
    `/participant/stratedge/getresults?game_session_id=${gameSessionId}`,
  )
    .then((response) => {
      if (response.data.round_scenarios.length > 0) {
        response.data.round_scenarios.sort(function (a, b) {
          return a.round_id - b.round_id;
        });
      }
      if (response.data.rounds.length > 0) {
        response.data.rounds.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function updateStratEdgeProductService(product) {
  const { token } = getLoggedInUser();

  const formData = new FormData();

  if (product.image) formData.append('image', product.image);

  formData.append('id', product.id);

  formData.append('name', product.name);

  formData.append('stratEdge_configuration_id', product.stratEdgeConfigurationId);

  formData.append('description', product.description);

  formData.append('image_path', product.imagePath);

  formData.append('token', token);

  return httpClient_post(
    '/stratedge/updateproduct.php',
    formData,
    process.env.REACT_APP_PHP_SERVICE_BASE_URL
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function updateStratEdgeCompetitorService(competitor) {
  const { token } = getLoggedInUser();

  const formData = new FormData();

  if (competitor.image) formData.append('image', competitor.image);

  formData.append('id', competitor.id);

  formData.append('name', competitor.name);

  formData.append(
    'stratEdge_configuration_id',
    competitor.stratEdgeConfigurationId,
  );

  formData.append('description', competitor.description);

  formData.append('image_path', competitor.imagePath);

  formData.append('chiffres_cles', competitor.chiffresCles);

  formData.append('production_cost', competitor.productionCost);

  formData.append('production_capacity', competitor.productionCapacity);

  formData.append('fret_market1', competitor.fretMarket1);

  formData.append('fret_market2', competitor.fretMarket2);

  formData.append('token', token);

  return httpClient_post(
    '/stratedge/updatecompetitor.php',
    formData,
    process.env.REACT_APP_PHP_SERVICE_BASE_URL
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function updateStratEdgeStrategicDecisionService(competitor) {
  const { token } = getLoggedInUser();

  const formData = new FormData();

  if (competitor.img) formData.append('image', competitor.img);

  formData.append('id', competitor.id);

  formData.append('name', competitor.name);

  formData.append('token', token);

  formData.append('image_path', competitor.imagePath);

  formData.append(
    'stratEdge_configuration_id',
    competitor.stratEdgeConfigurationId,
  );

  formData.append('decision_type', competitor.decisionType);

  formData.append('price', competitor.price);

  formData.append('cost_impact', competitor.costImpact);

  formData.append('capacity_impact', competitor.capacityImpact);

  formData.append('fret_impact_market2', competitor.fretImpactMarket2);

  formData.append('fret_impact_market1', competitor.fretImpactMarket1);

  return httpClient_post(
    '/stratedge/updatestrategicdecision.php',
    formData,
    process.env.REACT_APP_PHP_SERVICE_BASE_URL
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function updateStratEdgeMarketService(competitor) {
  const { token } = getLoggedInUser();

  const formData = new FormData();

  if (competitor.image) formData.append('image', competitor.image);

  formData.append('id', competitor.id);

  formData.append('name', competitor.name);

  formData.append(
    'stratEdge_configuration_id',
    competitor.stratEdgeConfigurationId,
  );

  formData.append('description', competitor.description);

  formData.append('image_path', competitor.imagePath);

  formData.append('demand0', competitor.demand0);
  formData.append('demand1', competitor.demand1);
  formData.append('demand2', competitor.demand2);
  formData.append('demand3', competitor.demand3);
  formData.append('demand4', competitor.demand4);
  formData.append('demand5', competitor.demand5);

  formData.append('token', token);

  return httpClient_post(
    '/stratedge/updatemarket.php',
    formData,
    process.env.REACT_APP_PHP_SERVICE_BASE_URL
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export {
  getStratEdgeConfigurationService,
  updateStratEdgeProductService,
  updateStratEdgeCompetitorService,
  updateStratEdgeMarketService,
  updateStratEdgeStrategicDecisionService,
  getStratEdgeResultService,
  startOrStopRoundService,
  getStratEdgeResultParticipantService,
  getStratEdgeConfigurationParticipantService,
  initSimulationService,
  runSimulationService,
  saveScenarioService,
  getScenariosService,
  deleteScenarioService,
  sendDecisionService,
  chooseDecisionService,
  getStratEdgeGameSessionConfigurationService,
  submittedDecisionsService,
  resetRoundService
};
