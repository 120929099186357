import { httpClient_get, httpClient_post } from "../../helpers/api";
const responsesDay7 = [
  //NOVICE
  {
    id: 9,
    classificationEIM: "A",
    delai: "suggestif",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "oui",
    facteursFav: "non",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",
    classificationEIMId: 233,
    delaiId: 234,
    evolutionId: 235,
    rechallengeId: 236,
    evocateurRoleId: 237,
    facteursFavId: 238,
    autresEtiologiesId: 239,
    testSpecifiquesId: 240,
    scoreBiblioId: 241,
    inputOMSId: 242,
  },
  {
    id: 10,
    classificationEIM: "A",
    delai: "compatible",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "oui",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIMId: 243,
    delaiId: 244,
    evolutionId: 245,
    rechallengeId: 246,
    evocateurRoleId: 247,
    facteursFavId: 248,
    autresEtiologiesId: 249,
    testSpecifiquesId: 250,
    scoreBiblioId: 251,
    inputOMSId: 252,
  },
  {
    id: 11,
    classificationEIM: "",
    delai: "incompatible",
    evolution: "",
    rechallenge: "",
    evocateurRole: "",
    facteursFav: "",
    autresEtiologies: "",
    testSpecifiques: "",
    scoreBiblio: "",
    inputOMS: "",

    classificationEIMId: 253,
    delaiId: 254,
    evolutionId: 255,
    rechallengeId: 256,
    evocateurRoleId: 257,
    facteursFavId: 258,
    autresEtiologiesId: 259,
    testSpecifiquesId: 260,
    scoreBiblioId: 261,
    inputOMSId: 262,
  },
  {
    id: 12,
    classificationEIM: "",
    delai: "incompatible",
    evolution: "",
    rechallenge: "",
    evocateurRole: "",
    facteursFav: "",
    autresEtiologies: "",
    testSpecifiques: "",
    scoreBiblio: "",
    inputOMS: "",

    classificationEIMId: 263,
    delaiId: 264,
    evolutionId: 265,
    rechallengeId: 266,
    evocateurRoleId: 267,
    facteursFavId: 268,
    autresEtiologiesId: 269,
    testSpecifiquesId: 270,
    scoreBiblioId: 271,
    inputOMSId: 272,
  },

  //Intermédiaire
  {
    id: 13,
    classificationEIM: "B",
    delai: "suggestif",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIMId: 273,
    delaiId: 274,
    evolutionId: 275,
    rechallengeId: 276,
    evocateurRoleId: 277,
    facteursFavId: 278,
    autresEtiologiesId: 279,
    testSpecifiquesId: 280,
    scoreBiblioId: 281,
    inputOMSId: 282,
  },
  {
    id: 14,
    isComplicated: true,
    classificationEIM: "B",
    delai: "suggestif",
    evolution: "non_concluante",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "non",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",
    classificationEIM2: "B",
    delai2: "suggestif",
    evolution2: "non_concluante",
    rechallenge2: "R0",
    evocateurRole2: "non",
    facteursFav2: "non",
    autresEtiologies2: "absentes_apres_bilan_appropries",
    testSpecifiques2: "L0",
    scoreBiblio2: "B4",
    inputOMS2: "compatible",

    classificationEIMId: 283,
    delaiId: 284,
    evolutionId: 285,
    rechallengeId: 286,
    evocateurRoleId: 287,
    facteursFavId: 288,
    autresEtiologiesId: 289,
    testSpecifiquesId: 290,
    scoreBiblioId: 291,
    inputOMSId: 292,
    classificationEIMId2: 293,
    delaiId2: 294,
    evolutionId2: 295,
    rechallengeId2: 296,
    evocateurRoleId2: 297,
    facteursFavId2: 298,
    autresEtiologiesId2: 299,
    testSpecifiquesId2: 300,
    scoreBiblioId2: 301,
    inputOMSId2: 302,
  },
  {
    //twices
    id: 15,
    isComplicated: true,
    classificationEIM: "B",
    delai: "compatible",
    evolution: "non_concluante",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "non",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIM2: "B",
    delai2: "suggestif",
    evolution2: "non_concluante",
    rechallenge2: "R0",
    evocateurRole2: "non",
    facteursFav2: "oui",
    autresEtiologies2: "absentes_apres_bilan_appropries",
    testSpecifiques2: "L0",
    scoreBiblio2: "B4",
    inputOMS2: "compatible",

    classificationEIMId: 303,
    delaiId: 304,
    evolutionId: 305,
    rechallengeId: 306,
    evocateurRoleId: 307,
    facteursFavId: 308,
    autresEtiologiesId: 309,
    testSpecifiquesId: 310,
    scoreBiblioId: 311,
    inputOMSId: 312,
    classificationEIMId2: 313,
    delaiId2: 314,
    evolutionId2: 315,
    rechallengeId2: 316,
    evocateurRoleId2: 317,
    facteursFavId2: 318,
    autresEtiologiesId2: 319,
    testSpecifiquesId2: 320,
    scoreBiblioId2: 321,
    inputOMSId2: 322,
  },
  {
    id: 16,
    classificationEIM: "B",
    delai: "compatible",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "oui",
    autresEtiologies: "non_recherchees",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",
    classificationEIMId: 323,
    delaiId: 324,
    evolutionId: 325,
    rechallengeId: 326,
    evocateurRoleId: 327,
    facteursFavId: 328,
    autresEtiologiesId: 329,
    testSpecifiquesId: 330,
    scoreBiblioId: 331,
    inputOMSId: 332,
  },
  //Expert
  {
    //twices
    id: 17,
    isComplicated: true,
    classificationEIM: "A",
    delai: "compatible",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "oui",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIM2: "A",
    delai2: "compatible",
    evolution2: "suggestive",
    rechallenge2: "R0",
    evocateurRole2: "oui",
    facteursFav2: "oui",
    autresEtiologies2: "absentes_apres_bilan_appropries",
    testSpecifiques2: "L0",
    scoreBiblio2: "B4",
    inputOMS2: "compatible",

    classificationEIMId: 333,
    delaiId: 334,
    evolutionId: 335,
    rechallengeId: 336,
    evocateurRoleId: 337,
    facteursFavId: 338,
    autresEtiologiesId: 339,
    testSpecifiquesId: 340,
    scoreBiblioId: 341,
    inputOMSId: 342,
    classificationEIMId2: 343,
    delaiId2: 344,
    evolutionId2: 345,
    rechallengeId2: 346,
    evocateurRoleId2: 347,
    facteursFavId2: 348,
    autresEtiologiesId2: 349,
    testSpecifiquesId2: 350,
    scoreBiblioId2: 351,
    inputOMSId2: 352,
  },
  {
    //twices
    id: 18,
    isComplicated: true,
    classificationEIM: "B",
    delai: "compatible",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIM2: "B",
    delai2: "suggestif",
    evolution2: "suggestive",
    rechallenge2: "R0",
    evocateurRole2: "non",
    facteursFav2: "oui",
    autresEtiologies2: "absentes_apres_bilan_appropries",
    testSpecifiques2: "L0",
    scoreBiblio2: "B4",
    inputOMS2: "compatible",

    classificationEIMId: 353,
    delaiId: 354,
    evolutionId: 355,
    rechallengeId: 356,
    evocateurRoleId: 357,
    facteursFavId: 358,
    autresEtiologiesId: 359,
    testSpecifiquesId: 360,
    scoreBiblioId: 361,
    inputOMSId: 362,
    classificationEIMId2: 363,
    delaiId2: 364,
    evolutionId2: 365,
    rechallengeId2: 366,
    evocateurRoleId2: 367,
    facteursFavId2: 368,
    autresEtiologiesId2: 369,
    testSpecifiquesId2: 370,
    scoreBiblioId2: 371,
    inputOMSId2: 372,
  },
  {
    //twices
    id: 19,
    isComplicated: true,
    classificationEIM: "B",
    delai: "suggestif",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "non",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    classificationEIM2: "B",
    delai2: "compatible",
    evolution2: "suggestive",
    rechallenge2: "R0",
    evocateurRole2: "non",
    facteursFav2: "non",
    autresEtiologies2: "absentes_apres_bilan_appropries",
    testSpecifiques2: "L0",
    scoreBiblio2: "B4",
    inputOMS2: "compatible",

    classificationEIMId: 373,
    delaiId: 374,
    evolutionId: 375,
    rechallengeId: 376,
    evocateurRoleId: 377,
    facteursFavId: 378,
    autresEtiologiesId: 379,
    testSpecifiquesId: 380,
    scoreBiblioId: 381,
    inputOMSId: 382,
    classificationEIMId2: 383,
    delaiId2: 384,
    evolutionId2: 385,
    rechallengeId2: 386,
    evocateurRoleId2: 387,
    facteursFavId2: 388,
    autresEtiologiesId2: 389,
    testSpecifiquesId2: 390,
    scoreBiblioId2: 391,
    inputOMSId2: 392,
  },
  {
    //twices
    id: 20,
    //isComplicated: true,
    classificationEIM: "A",
    delai: "compatible",
    evolution: "suggestive",
    rechallenge: "R0",
    evocateurRole: "oui",
    facteursFav: "oui",
    autresEtiologies: "absentes_apres_bilan_appropries",
    testSpecifiques: "L0",
    scoreBiblio: "B4",
    inputOMS: "",

    // classificationEIM2: "B",
    // delai2: "compatible",
    // evolution2: "suggestive",
    // rechallenge2: "R0",
    // evocateurRole2: "non",
    // facteursFav2: "non",
    // autresEtiologies2: "absentes_apres_bilan_appropries",
    // testSpecifiques2: "L0",
    // scoreBiblio2: "B4",
    // inputOMS2: "possible",

    classificationEIMId: 393,
    delaiId: 394,
    evolutionId: 395,
    rechallengeId: 396,
    evocateurRoleId: 397,
    facteursFavId: 398,
    autresEtiologiesId: 399,
    testSpecifiquesId: 400,
    scoreBiblioId: 401,
    inputOMSId: 402,
    // classificationEIMId2: 393,
    // delaiId2: 394,
    // evolutionId2: 395,
    // rechallengeId2: 396,
    // evocateurRoleId2: 397,
    // facteursFavId2: 398,
    // autresEtiologiesId2: 399,
    // testSpecifiquesId2: 400,
    // scoreBiblioId2: 401,
    // inputOMSId2: 402,
  },
];
const validCasService = (cas, _idCas, _idLevel) => {


  const correctResponse = responsesDay7.find((r) => r.id === _idCas);

  if (cas.classificationEIM.response === correctResponse.classificationEIM) {
    cas.classificationEIM.isValid = 1;
  } else cas.classificationEIM.isValid = 0;

  if (cas.delai.response === correctResponse.delai) {
    cas.delai.isValid = 1;
  } else cas.delai.isValid = 0;

  if (cas.evolution.response === correctResponse.evolution) {
    cas.evolution.isValid = 1;
  } else cas.evolution.isValid = 0;

  if (cas.rechallenge.response === correctResponse.rechallenge) {
    cas.rechallenge.isValid = 1;
  } else cas.rechallenge.isValid = 0;

  if (cas.evocateurRole.response === correctResponse.evocateurRole) {
    cas.evocateurRole.isValid = 1;
  } else cas.evocateurRole.isValid = 0;

  if (cas.facteursFav.response === correctResponse.facteursFav) {
    cas.facteursFav.isValid = 1;
  } else cas.facteursFav.isValid = 0;

  if (cas.autresEtiologies.response === correctResponse.autresEtiologies) {
    cas.autresEtiologies.isValid = 1;
  } else cas.autresEtiologies.isValid = 0;

  if (cas.testSpecifiques.response === correctResponse.testSpecifiques) {
    cas.testSpecifiques.isValid = 1;
  } else cas.testSpecifiques.isValid = 0;

  if (cas.scoreBiblio.response === correctResponse.scoreBiblio) {
    cas.scoreBiblio.isValid = 1;
  } else cas.scoreBiblio.isValid = 0;

  if (cas.inputOMS.response === correctResponse.inputOMS) {
    cas.inputOMS.isValid = 1;
  } else cas.inputOMS.isValid = 0;

  if (correctResponse.isComplicated) {
    if (
      cas.classificationEIM2.response === correctResponse.classificationEIM2
    ) {
      cas.classificationEIM2.isValid = 1;
    } else cas.classificationEIM2.isValid = 0;

    if (cas.delai2.response === correctResponse.delai2) {
      cas.delai2.isValid = 1;
    } else cas.delai2.isValid = 0;

    if (cas.evolution2.response === correctResponse.evolution2) {
      cas.evolution2.isValid = 1;
    } else cas.evolution2.isValid = 0;

    if (cas.rechallenge2.response === correctResponse.rechallenge2) {
      cas.rechallenge2.isValid = 1;
    } else cas.rechallenge2.isValid = 0;

    if (cas.evocateurRole2.response === correctResponse.evocateurRole2) {
      cas.evocateurRole2.isValid = 1;
    } else cas.evocateurRole2.isValid = 0;

    if (cas.facteursFav2.response === correctResponse.facteursFav2) {
      cas.facteursFav2.isValid = 1;
    } else cas.facteursFav2.isValid = 0;

    if (cas.autresEtiologies2.response === correctResponse.autresEtiologies2) {
      cas.autresEtiologies2.isValid = 1;
    } else cas.autresEtiologies2.isValid = 0;

    if (cas.testSpecifiques2.response === correctResponse.testSpecifiques2) {
      cas.testSpecifiques2.isValid = 1;
    } else cas.testSpecifiques2.isValid = 0;

    if (cas.scoreBiblio2.response === correctResponse.scoreBiblio2) {
      cas.scoreBiblio2.isValid = 1;
    } else cas.scoreBiblio2.isValid = 0;

    if (cas.inputOMS2.response === correctResponse.inputOMS2) {
      cas.inputOMS2.isValid = 1;
    } else cas.inputOMS2.isValid = 0;
  }

  if (
    cas.classificationEIM.isValid === 1 &&
    cas.delai.isValid === 1 &&
    cas.evolution.isValid === 1 &&
    cas.rechallenge.isValid === 1 &&
    cas.evocateurRole.isValid === 1 &&
    cas.facteursFav.isValid === 1 &&
    cas.autresEtiologies.isValid === 1 &&
    cas.testSpecifiques.isValid === 1 &&
    cas.scoreBiblio.isValid === 1
    //&& cas.inputOMS.isValid === 1
  ) {
    if (correctResponse.isComplicated) {
      if (
        cas.classificationEIM2.isValid === 1 &&
        cas.delai2.isValid === 1 &&
        cas.evolution2.isValid === 1 &&
        cas.rechallenge2.isValid === 1 &&
        cas.evocateurRole2.isValid === 1 &&
        cas.facteursFav2.isValid === 1 &&
        cas.autresEtiologies2.isValid === 1 &&
        cas.testSpecifiques2.isValid === 1 &&
        cas.scoreBiblio2.isValid === 1
        //&& cas.inputOMS2.isValid === 1
      ) {
        cas.isValid = 1;
      } else {
        cas.isValid = 0;
      }
    } else {
      cas.isValid = 1;
    }
  } else {
    cas.isValid = 0;
  }

  return { cas, _idCas, _idLevel };
};

const saveDetailsService = (dayId, centerId, details) => {
  return httpClient_post(`/participant/pvgame/savedecisiondetails`, {
    centerId,
    dayId,
    details,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const saveDecisionsService = (dayId, decisions, centerId) => {
  return httpClient_post(`/participant/pvgame/savedecisions`, {
    centerId,
    decisions,
    dayId,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const activateImputabilityBadgeService = (centerId, level) => {
  return httpClient_post(
    `/participant/pvgame/activateimputabilitybadge?centerId=${centerId}&level=${level}`,
    {}
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
const closeDayService = (dayId, centerId) => {
  return httpClient_get(
    `/participant/pvgame/closeday?centerId=${centerId}&dayId=${dayId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getDetailsService = (dayId, centerId, parse = true) => {
  return httpClient_get(
    `/participant/pvgame/getdecisiondetails?centerId=${centerId}&dayId=${dayId}`
  )
    .then((response) => {
      if (!parse) {
        return response.data[0];
      }
      if (response.data.length > 0) {
        const data = response.data[0];
        data.details = JSON.parse(data.details);
        return data;
      } else {
        return {};
      }
    })
    .catch((error) => {
      throw error;
    });
};


const getHistoricScores = (centerId) => {

  return httpClient_get(
    `/participant/pvgame/gethistoricscores?centerId=${centerId}`
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export {
  validCasService,
  saveDetailsService,
  saveDecisionsService,
  closeDayService,
  getDetailsService,
  activateImputabilityBadgeService,
  getHistoricScores,
  responsesDay7
};



// [
//   233,
//   234,
//   235,
//   236,
//   237,
//   238,
//   239,
//   240,
//   241,

//   243,
//   244,
//   245,
//   246,
//   247,
//   248,
//   249,
//   250,
//   251,
  
//   253,
//   254,
//   255,
//   256,
//   257,
//   258,
//   259,
//   260,
//   261,
  
//   263,
//   264,
//   265,
//   266,
//   267,
//   268,
//   269,
//   270,
//   271,
  
//   273,
//   274,
//   275,
//   276,
//   277,
//   278,
//   279,
//   280,
//   281,
  
//   283,
//   284,
//   285,
//   286,
//   287,
//   288,
//   289,
//   290,
//   291,
  
//   293,
//   294,
//   295,
//   296,
//   297,
//   298,
//   299,
//   300,
//   301,
  
//   303,
//   304,
//   305,
//   306,
//   307,
//   308,
//   309,
//   310,
//   311,
  
//   313,
//   314,
//   315,
//   316,
//   317,
//   318,
//   319,
//   320,
//   321,
  
//   323,
//   324,
//   325,
//   326,
//   327,
//   328,
//   329,
//   330,
//   331,
  
//   333,
//   334,
//   335,
//   336,
//   337,
//   338,
//   339,
//   340,
//   341,
  
//   343,
//   344,
//   345,
//   346,
//   347,
//   348,
//   349,
//   350,
//   351,

//   353,
//   354,
//   355,
//   356,
//   357,
//   358,
//   359,
//   360,
//   361,
  
//   363,
//   364,
//   365,
//   366,
//   367,
//   368,
//   369,
//   370,
//   371,
  
//   373,
//   374,
//   375,
//   376,
//   377,
//   378,
//   379,
//   380,
//   381,
  
//   383,
//   384,
//   385,
//   386,
//   387,
//   388,
//   389,
//   390,
//   391,
  
//   393,
//   394,
//   395,
//   396,
//   397,
//   398,
//   399,
//   400,
//   401
// ]