import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useTranslation } from 'react-i18next';

const defaultOptions = {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#1e7ac5',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    // width: undefined,
    zIndex: 100,
};


const Tour = ({ itemsRef }) => {

    const { t } = useTranslation();
    const TOUR_STEPS = [
        {
            target: ".tour-analyse",
            content: t("stratEdge.tour_steps.analyse"),
            disableBeacon: true,
        },
        {
            target: ".tour-simulations-scenarios",
            content: t("stratEdge.tour_steps.simulation"),
        },
        {
            target: ".tour-simulations-scenarios-stp1",
            content: t("stratEdge.tour_steps.step1"),
        },
        {
            target: ".tour-simulations-scenarios-stp2",
            content: t("stratEdge.tour_steps.step2"),
        },
        {
            target: ".tour-simulations-scenarios-stp3",
            content: t("stratEdge.tour_steps.step3"),
        },
        {
            target: ".tour-results",
            content: t("stratEdge.tour_steps.results"),
        }


    ];


    const INITIAL_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: TOUR_STEPS
    };


    const reducer = (state = INITIAL_STATE, action) => {
        switch (action.type) {
            case "START":
                return { ...state, run: true };
            case "RESET":
                return { ...state, stepIndex: 0 };
            case "STOP":
                return { ...state, run: false };
            case "NEXT_OR_PREV":
                return { ...state, ...action.payload };
            case "RESTART":
                return {
                    ...state,
                    stepIndex: 0,
                    run: true,
                    loading: false,
                    key: new Date()
                };
            default:
                return state;
        }
    };

    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const elem = document.querySelector(".tour-simulations-scenarios > a > ul");

    useEffect(() => {
        if (!localStorage.getItem("tour")) {
            dispatch({ type: "START" });
        }
    }, []);

    const setTourViewed = () => {
        localStorage.setItem("tour", "1");
    };

    const callback = (data) => {
        const { action, index, type, status } = data;


        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            elem.style.display = "none";
            setTourViewed();
            dispatch({ type: "STOP" });
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {

            if (index >= 0 && index < 4 && action === ACTIONS.NEXT) {
                elem.style.display = "inline-block";
            }
            else if (index === 5 && action === ACTIONS.PREV) {
                elem.style.display = "inline-block";
            }
            else if ((index === 4 && action === ACTIONS.NEXT) || (index === 1 && action === ACTIONS.PREV)) {
                elem.style.display = "none";
            }

            dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
            });
        }
    };

    const startTour = () => {
        dispatch({ type: "RESTART" });
    };

    return (
        <>
            <JoyRide
                {...tourState}
                callback={callback}
                showSkipButton={true}
                continuous={true}
                styles={{
                    tooltipContainer: {
                        textAlign: "left"
                    },
                    buttonBack: {
                        marginRight: 10
                    },
                    options: defaultOptions
                }}
                locale={{
                    last: "End tour"
                }}
            />
        </>
    );
};

export default Tour;
