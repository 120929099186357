import { httpClient_get, httpClient_post } from "../../helpers/api";

function getNotionsModuleByIDService(moduleInstanceId) {
  return httpClient_get(
    `/moderator/getnotions?module_instance_id=${moduleInstanceId}`
  )
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function addNotionsModuleService({
  module_instance_id,
  notion_order,
  title,
  content,
  media_path,
}) {
  return httpClient_post(`/moderator/addnotion`, {
 module_instance_id,
  notion_order,
  title,
  content,
  media_path,
  })
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function editNotionsModuleService({
  notion_id,
  module_instance_id,
  notion_order,
  title,
  content,
  media_path,
}) {
  return httpClient_post(`/moderator/updatenotion`, {
  notion_id,
  module_instance_id,
  notion_order,
  title,
  content,
  media_path,
  })
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function deleteNotionsModuleService(notionId) {
  return httpClient_post(`/moderator/deletenotion?notion_id=${notionId}`, null)
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

function orderNotionsModuleService(moduleInstanceId, data) {
  return httpClient_post(
    `/moderator/updatenotionsorder?module_instance_id=${moduleInstanceId}`,
    data
  )
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export {
  getNotionsModuleByIDService,
  addNotionsModuleService,
  editNotionsModuleService,
  deleteNotionsModuleService,
  orderNotionsModuleService,
};
