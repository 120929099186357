import React, { useEffect, useState } from 'react';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import { Container, Row, Col, Tooltip } from 'reactstrap';
import NoPathCopie from '../../assets/images/NoPathCopie.png';
import NoPathUser from '../../assets/images/NoPathUser.png';
import Groupeu476 from '../../assets/images/Groupeu476.svg';
import group789 from '../../assets/images/group789.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getStratEdgeConfigurationParticipant,
    getStratEdgeResultParticipant, initSimulations,
} from '../../redux/StratEdge/actions';
import { useTranslation } from 'react-i18next';

import Loader from '../../components/Loader';
const Item = (props) => {
    const { status, name, stratEdgeSelector, roundId, dispatch, t } = props;
    const [colorStatus, setColorStatus] = useState('#10C46E');
    const [statusName, setStatusName] = useState(t('stratEdge.buisnessGame.round.statusFinish'));
    const [result, setResult] = useState(false);

    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId,
    );
    const Module = useSelector(
        (state) => state.Module,
    );


    const history = useHistory();




    useEffect(() => {
        switch (status) {
            case -1:
                setColorStatus('#FF5B5B');
                setStatusName(t('stratEdge.buisnessGame.round.statusComing'));
                break;
            case 1:
                setColorStatus('#10C46E');
                setStatusName(t('stratEdge.buisnessGame.round.statusFinish'));
                break;
            case 0:
                setColorStatus('#F9C851');
                setStatusName(t('stratEdge.buisnessGame.round.statusInProgress'));
                break;

            default:
                break;
        }
    }, [props]);

    useEffect(() => {
        if (stratEdgeSelector.resultParticipant.round_scenarios.length > 0) {
            let obj = stratEdgeSelector.resultParticipant.round_scenarios.find(
                (rs) => rs.round_id === roundId,
            );

            if (obj) {
                obj.scenario_results.sort(function (a, b) {

                    return (b.reserve) - (a.reserve);
                });
                obj.teamCompetitors =
                    (stratEdgeSelector.configParticipant && stratEdgeSelector.configParticipant.team_competitors);
                setResult(obj);
            }
        }
    }, [stratEdgeSelector]);
    const colorBackground = status === -1 ? '#D8D8D8' : '#FFFFFF';
    const UnlockState = status === -1 || status === 1 ? false : true;
    const StratEdge = useSelector((state) => state.StratEdge);

    const ImageCompetitor = (props) => {
        const { competitor, index } = props;
        const [tooltipOpen, setTooltipOpen] = useState(false);

        const toggle = () => setTooltipOpen(!tooltipOpen);

        if (status === 1)
            return (
                <>
                    <img
                        id={'image' + index + (competitor ? competitor.id : 0)}
                        src={(competitor && competitor.image_path !== null && competitor.image_path !== "null") ? competitor.image_path : NoPathCopie}
                        alt="img"
                        width={25}
                        style={{ marginRight: '2px', borderRadius: 25 }}
                    />


                    <Tooltip
                        placement="top"
                        isOpen={tooltipOpen}
                        target={'image' + index + (competitor ? competitor.id : 0)}
                        toggle={toggle}>
                        {index} : {competitor && competitor.name}
                    </Tooltip>
                </>
            );
        else
            return (
                <>
                    <img
                        id={'image' + competitor.id}
                        src={NoPathUser}
                        alt="img"
                        width={25}
                        style={{ marginRight: '2px' }}
                    />
                </>
            );
    };

    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: colorBackground, color: '#fff' }}
            contentArrowStyle={{ display: 'none' }}
            iconStyle={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: ' 4px solid ' + colorStatus,
            }}>
            <div
                style={{ color: 'black', cursor: status === 0 ? 'pointer' : 'default' }}
                onClick={() => {

                    status === 0 &&
                        dispatch(
                            getStratEdgeConfigurationParticipant(gameSessionId, () => {
                                dispatch(
                                    initSimulations(
                                        StratEdge.configParticipant,
                                        StratEdge.resultParticipant
                                    )
                                );
                            }, history),
                        );
                }}>
                <div
                    style={{
                        color: 'black',
                        display: 'flex',
                        alignContent: 'space-between',
                        justifyContent: 'space-between',
                    }}>
                    <div className="tim-title">{name}</div>
                    <div>
                        <i
                            className={`fas ${UnlockState ? 'fa-unlock' : 'fa-lock'}`}
                            style={{
                                fontSize: '20px',
                                color: UnlockState ? '#71B6F9' : '#9F9F9F',
                            }}></i>
                    </div>
                </div>
                <div
                    className="tim-sou-title"
                    style={{
                        color: colorStatus,
                    }}>
                    {statusName}
                </div>
                <div
                    style={{
                        color: 'black',
                        display: 'flex',
                        alignContent: 'space-between',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <div className="tim-group-title">{t('stratEdge.buisnessGame.round.classement')}</div>
                    <div>

                        {result
                            ?
                            result.scenario_results &&
                            result.scenario_results.map((sr, index) => {
                                const teamCompetitor = result?.teamCompetitors?.find(
                                    (tc) => tc.team_id === sr.team_id,
                                );

                                
                                const competitor = stratEdgeSelector?.configParticipant?.competitors?.find(
                                    (c) => c.id === (teamCompetitor && teamCompetitor.competitor_id),

                                );

                                if (competitor)
                                    return (
                                        <>
                                         <ImageCompetitor
                                             competitor={competitor}
                                             index={index + 1}
                                         />
                                        </>
                                    );

                                else return <></>
                            })
                            : stratEdgeSelector.configParticipant &&
                            stratEdgeSelector.configParticipant.competitors.map(
                                (c, index) => (
                                    <>
                                                                        <ImageCompetitor competitor={c} index={index + 1} />

                                    </>
                                ),
                            )}
                    </div>
                </div>
            </div>
        </VerticalTimelineElement>
    );
};

const BsTimeline = () => {
    const gameSessionId = useSelector(
        (state) => state.Module.module.gameSessionId,
    );
  
    
    const stratEdgeSelector = useSelector((state) => state.StratEdge);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getStratEdgeConfigurationParticipant(gameSessionId, () => {
            dispatch(getStratEdgeResultParticipant(gameSessionId));

        }));
    }, []);
    const { t } = useTranslation();

    const { competitor_id = 0 } = stratEdgeSelector?.configParticipant?.team_competitors.find(item => item.team_id === stratEdgeSelector.configParticipant.player_team_id) || {};

    const competitor = stratEdgeSelector?.configParticipant?.competitors.find(item => item.id === competitor_id);

    return (
        <>
            {stratEdgeSelector.loading && <Loader />}
            <Row>
                <div
                    className="d-flex ml-4 mt-3 "
                    style={{
                        alignItems: 'center',
                    }}>
                    <img src={group789} alt="img" width={19} height={32} />

                    <div className="tim-g-title">{t('stratEdge.title')}</div>
                </div>
            </Row>
            <Container>
                <Row>
                    <Col lg={7}>
                        <Row style={{ justifyContent: "center", marginBottom: "20px" }}  >
                            <Col lg={4}>
                                <h3> {t("stratEdge.votre_equipe")} </h3>
                            </Col>
                            <Col lg={3}>
                                <img
                                    src={(competitor && competitor.image_path !== null && competitor.image_path !== "null") ? competitor.image_path : ""}
                                    alt="img"
                                    width={50}
                                    height={50}
                                    style={{ marginRight: '2px', borderRadius: 25 }}
                                />
                            </Col>
                        </Row>
                        <VerticalTimeline>
                            {stratEdgeSelector.resultParticipant.rounds &&
                                stratEdgeSelector.resultParticipant.rounds.map(
                                    (item, index) => (
                                        <Item
                                            stratEdgeSelector={stratEdgeSelector}
                                            roundId={item.id}
                                            status={item.status}
                                            name={item.name}
                                            dispatch={dispatch}
                                            key={index}
                                            t={t}
                                        />
                                    ),
                                )}
                        </VerticalTimeline>
                    </Col>
                    <Col lg={4}>
                        {!stratEdgeSelector.loading &&
                            <img
                                src={Groupeu476}
                                alt="img"
                                style={{
                                    bottom: '0px',
                                    position: 'absolute',
                                }}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export { BsTimeline };
