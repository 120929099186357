import { httpClient_get, httpClient_post } from "../../helpers/api";

function getSessionsModuleByIDService(moduleInstanceId) {
  //return sessionsModule;

  return httpClient_get(
    `/moderator/gettrainingsessions?module_affectation_id=${moduleInstanceId}`
  )
    .then((response) => {
      console.log("response.data ********************** \n", response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function addSessionModuleService({
  module_instance_id,
  training_session_name,
  start_date,
  end_date,
}) {
  const data = { module_instance_id, training_session_name, start_date, end_date };
  return httpClient_post(`/moderator/addtrainingsession`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function deleteSessionModuleService({ trainingSessionId }) {
  return httpClient_post(
    `/moderator/deletetrainingsession?training_session_id=${trainingSessionId}`,
    null
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function archiveSessionModuleService({ trainingSessionId }) {
  return httpClient_post(
    `/moderator/archivetrainingsession?training_session_id=${trainingSessionId}`,
    null
  )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function editSessionModuleService({
  training_session_id,
  module_instance_id,
  training_session_name,
  start_date,
  end_date,
}) {
  const data = {
   training_session_id,
   module_instance_id,
   training_session_name,
   start_date,
   end_date,
  };

console.log('object', data, )
  return httpClient_post(`/moderator/updatetrainingsession`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export {
  getSessionsModuleByIDService,
  addSessionModuleService,
  deleteSessionModuleService,
  editSessionModuleService,
  archiveSessionModuleService
};
