import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";
import swal from 'sweetalert';
import BookOpen from "../../../assets/images/book-open-solid.svg";
import ChimneyBlue from "../../../assets/images/chimney_blue.svg";
import StatsGroupe from "../../../assets/images/groupe332.svg";
import MegaphoneBlue from "../../../assets/images/megaphone_blue.svg";
import PlayCircle from "../../../assets/images/play-circle-solid.svg";
import SetupBlue from "../../../assets/images/setup_blue.svg";
import StopCircle from "../../../assets/images/stop-circle-solid.svg";
import Team from "../../../assets/images/team.svg";
import TimesCircle from "../../../assets/images/times-circle-regular.svg";
import {
  getStratEdgeResult,
  startOrStopRound
} from "../../../redux/StratEdge/actions";

import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import ResultBusinessGame from "../../../components/ResultBusinessGame";
import SwalModal from "../../../components/SwalModal";
import { resetRoundService } from "../../../redux/StratEdge/service";

const DecisionsModal = (props) => {
  const [roundScenarios, setRoundScenarios] = useState(false);
  const stratEdgeSelector = useSelector((state) => state.StratEdge);

  const { t, selectedRoundId } = props;

  useEffect(() => {
    if (
      stratEdgeSelector.result &&
      stratEdgeSelector.result.round_scenarios &&
      stratEdgeSelector.result.round_scenarios.length > 0
    ) {
      let roundScenariosVAR =
        stratEdgeSelector.result.round_scenarios[
        stratEdgeSelector.result.round_scenarios.length - 1
        ];
      // const currentRoundActive = stratEdgeSelector.result.rounds.find(
      //   (r) => r.status === 0
      // );
      if (selectedRoundId) {
        const roundScenariosActive = stratEdgeSelector.result.round_scenarios.find(
          (rs) => rs.round_id === selectedRoundId
        );
        if (roundScenariosActive) roundScenariosVAR = roundScenariosActive;
      }
      setRoundScenarios(roundScenariosVAR);
    }
  }, [stratEdgeSelector, selectedRoundId]);

  const DecisionsExist = (props) => {
    const { decision } = props;
    return (
      <>
        <img
          alt="img"
          src={
            decision.image_path &&
              decision.image_path !== null &&
              decision.image_path !== "null"
              ? decision.image_path
              : "https://randomuser.me/api/portraits/men/3.jpg"
          }
          style={{
            borderRadius: 100,
            width: 29,
            height: 29,
            marginRight: 7,
          }}
        />
        <span style={{ fontSize: 12, fontFamily: "Karla", color: "#6C757D" }}>
          {decision && decision.name}
        </span>
      </>
    );
  };

  const DecisionsNotExist = (props) => {
    return (
      <>
        <img
          src={TimesCircle}
          alt="img"
        // style={{
        //   borderRadius: 100,
        //   width: 29,
        //   height: 29,
        //   marginRight: 7
        // }}
        />
      </>
    );
  };

  const ContentDecisions = (props) => {
    const { scenario } = props;

    return (
      <div
        className="decisions-modal-content"
        style={{ marginTop: 0, border: "0" }}
      >
        <div
          style={{
            marginTop: 21,
            marginLeft: 19,
            marginRight: 22,
            marginBottom: 27,
          }}
        >
          <Row>
            <Col lg={2}></Col>
            <Col lg={2} className="decisions-modal-content-header">
              <img
                alt="img"
                src={StatsGroupe}
                style={{
                  marginBottom: 5,
                }}
              />
              {t("stratEdge.config.AnsS.RDCost")}
            </Col>

            <Col lg={2} className="decisions-modal-content-header">
              <img
                alt="img"
                src={ChimneyBlue}
                style={{
                  marginBottom: 5,
                }}
              />

              {t("stratEdge.config.AnsS.RDCapacity")}
            </Col>

            <Col lg={2} className="decisions-modal-content-header">
              <img
                alt="img"
                src={MegaphoneBlue}
                style={{
                  marginBottom: 5,
                }}
              />

              {t("stratEdge.config.AnsS.marketing")}
            </Col>

            <Col lg={2} className="decisions-modal-content-header">
              <img
                alt="img"
                src={Team}
                style={{
                  marginBottom: 5,
                }}
              />

              {t("stratEdge.config.AnsS.RessourceHumain")}
            </Col>

            <Col lg={2} className="decisions-modal-content-header">
              <img
                alt="img"
                src={SetupBlue}
                style={{
                  marginBottom: 5,
                }}
              />
              {t("stratEdge.config.AnsS.maintenance")}
            </Col>
          </Row>

          {scenario &&
            scenario.team_scenarios &&
            scenario.team_scenarios.map((team) => (
              <Row
                style={{
                  backgroundColor: "#FBFBFB",
                  height: 50,
                  marginBottom: 8,
                  marginTop: 29,
                }}
              >
                <Col lg={2} className="decisions-modal-content-header">
                  <h5>{
                    team.competitor_fixed_name && stratEdgeSelector.config && stratEdgeSelector.config.competitors &&
                    stratEdgeSelector.config.competitors.find((c) => c.fixed_name === team.competitor_fixed_name).name

                  }</h5>
                </Col>
                <Col
                  lg={2}
                  className="decisions-modal-content-header"
                  style={{ flexDirection: "row" }}
                >
                  {team.strategic_decisions.filter(
                    (r) => r.decision_type === "r_d_cost"
                  )[0] ? (
                    <DecisionsExist
                      decision={
                        team.strategic_decisions.filter(
                          (r) => r.decision_type === "r_d_cost"
                        )[0]
                      }
                    />
                  ) : (
                    <DecisionsNotExist />
                  )}
                </Col>
                <Col
                  lg={2}
                  className="decisions-modal-content-header"
                  style={{ flexDirection: "row" }}
                >
                  {team.strategic_decisions.filter(
                    (r) => r.decision_type === "r_d_capacity"
                  )[0] ? (
                    <DecisionsExist
                      decision={
                        team.strategic_decisions.filter(
                          (r) => r.decision_type === "r_d_capacity"
                        )[0]
                      }
                    />
                  ) : (
                    <DecisionsNotExist />
                  )}
                </Col>
                <Col
                  lg={2}
                  className="decisions-modal-content-header"
                  style={{ flexDirection: "row" }}
                >
                  {team.strategic_decisions.filter(
                    (r) => r.decision_type === "marketing"
                  )[0] ? (
                    <DecisionsExist
                      decision={
                        team.strategic_decisions.filter(
                          (r) => r.decision_type === "marketing"
                        )[0]
                      }
                    />
                  ) : (
                    <DecisionsNotExist />
                  )}
                </Col>
                <Col
                  lg={2}
                  className="decisions-modal-content-header"
                  style={{ flexDirection: "row" }}
                >
                  {team.strategic_decisions.filter(
                    (r) => r.decision_type === "human_resources"
                  )[0] ? (
                    <DecisionsExist
                      decision={
                        team.strategic_decisions.filter(
                          (r) => r.decision_type === "human_resources"
                        )[0]
                      }
                    />
                  ) : (
                    <DecisionsNotExist />
                  )}
                </Col>
                <Col
                  lg={2}
                  className="decisions-modal-content-header"
                  style={{ flexDirection: "row" }}
                >
                  {team.strategic_decisions.filter(
                    (r) => r.decision_type === "maintenance"
                  )[0] ? (
                    <DecisionsExist
                      decision={
                        team.strategic_decisions.filter(
                          (r) => r.decision_type === "maintenance"
                        )[0]
                      }
                    />
                  ) : (
                    <DecisionsNotExist />
                  )}
                </Col>
              </Row>
            ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    // backdrop={"static"}
    >
      <Modal.Body>
        <h5>
          {t("stratEdge.buisnessGame.decisions")} :
          {roundScenarios &&
            stratEdgeSelector.result &&
            stratEdgeSelector.result.rounds &&
            stratEdgeSelector.result.rounds.find(
              (r) => r.id === roundScenarios.round_id
            ) &&
            stratEdgeSelector.result.rounds.find(
              (r) => r.id === roundScenarios.round_id
            ).name}
        </h5>
        <ContentDecisions scenario={roundScenarios} />
      </Modal.Body>
    </Modal>
  );
};

const RoundCard = (props) => {
  const { id, status, name, enablePlay, gameSessionId, dispatch, t, index, setCurrentRound, itemToReset, setItemToReset, reload } = props;

  const [colorStatus, setColorStatus] = useState("#10C46E");
  const [statusName, setStatusName] = useState("Terminé");


  useEffect(() => {
    switch (status) {
      case -1:
        setColorStatus("#FF5B5B");
        setStatusName(t("stratEdge.buisnessGame.round.statusComing"));
        break;
      case 1:
        setColorStatus("#10C46E");
        setStatusName(t("stratEdge.buisnessGame.round.statusFinish"));
        break;
      case 0:
        setColorStatus("#F9C851");
        setStatusName(t("stratEdge.buisnessGame.round.statusInProgress"));
        break;

      default:
        break;
    }
  }, [props]);

  const onClick = (status) => {
    SwalModal({
      text:
        status === 1
          ? t("stratEdge.buisnessGame.round.finishRoundMessage")
          : t("stratEdge.buisnessGame.round.startRoundMessage"),
      icon: "warning",
      buttons: [t("moduleNotion.swal.no"), t("moduleNotion.swal.yes")],
      confirmButtonColor: "#71B6F9",
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        setItemToReset(-1);
        dispatch(startOrStopRound(id, status, gameSessionId));
      }
    });
  };

  const onReset = () => {
    SwalModal({
      text: t("stratEdge.buisnessGame.round.resetRoundMessage"),
      icon: "warning",
      buttons: [t("moduleNotion.swal.no"), t("moduleNotion.swal.yes")],
      confirmButtonColor: "#71B6F9",
      dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        resetRoundService(id).then(data => {

          swal(t('stratEdge.buisnessGame.round.successResetRoundMessage'), {
            icon: "success",
          });

          reload();
        }).catch(error => {
          swal(t('stratEdge.buisnessGame.round.errorResetRoundMessage'), {
            icon: "error",
          });
        })
      }
    });
  }


  return (
    <div
      style={{
        paddingRight: 30,
        flex: 1,
        cursor: status === 1 ? "pointer" : "default",
      }}
      onClick={() => {
        if (status === 1) {
          props.onCLick(id);
          setCurrentRound(index + 1)
        }
      }}
    >
      <Card style={{ height: 63 }}>
        <CardBody
          className="card-box project-box mb-0 pb-0 p-11 "
          style={{ flex: 1, display: "flex" }}
        >
          <div className="inner-card-container">
            <div className="tour-container">
              <h4 className="tour-text" style={{ fontWeight: "bold" }}>
                {name}
              </h4>
            </div>
            <div className="status-container">
              <h4 className="status-text" style={{ color: colorStatus }}>
                {statusName}
              </h4>
            </div>
          </div>
          {itemToReset === id &&
            <div
              className="reset-btn"
              onClick={onReset}
            >
              <i className="fas fa-redo"></i>
            </div>
          }
          {status === 0 && (
            <>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => onClick(1)}
              >
                <img alt="" src={StopCircle} />
              </div>
            </>
          )}
          {status === -1 && enablePlay === id && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => onClick(0)}
            >
              <img alt="" src={PlayCircle} />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const StratEdgeAnalysis = (props) => {
  const [decisionsModalShow, setDecisionsModalShow] = useState(false);
  const [result, setResult] = useState(false);
  const [isFound, setIsFound] = useState(0);
  const [itemToReset, setItemToReset] = useState(-1);
  const currentSession = useSelector((state) => state.Session.selectedSession);
  const currentModule = useSelector((state) => state.Module.module);
  const stratEdgeSelector = useSelector((state) => state.StratEdge);
  const dispatch = useDispatch();
  const [selectedRoundId, setSelectedRoundId] = useState(false);
  const [currentRound, setCurrentRound] = useState(null);
  const { t } = useTranslation();
  const reload = () => dispatch(getStratEdgeResult(currentSession.gameSessionId));


  useEffect(() => {
    setIsFound(0);
    setCurrentRound(null);
    reload();
  }, []);
  useEffect(() => {
    if (
      stratEdgeSelector.config !== null &&
      stratEdgeSelector.result.round_scenarios &&
      stratEdgeSelector.result.round_scenarios.length > 0
    ) {
      let obj = stratEdgeSelector.result.round_scenarios.find(
        (rs) => rs.round_id === selectedRoundId
      );



      if (!obj) {
        obj =
          stratEdgeSelector.result.round_scenarios[
          stratEdgeSelector.result.round_scenarios &&
          stratEdgeSelector.result.round_scenarios.length - 1
          ];
      }

      if (obj) {
        obj.scenario_results.sort(function (a, b) {

          return (b.reserve) - (a.reserve);
        });
        obj.teamCompetitors = stratEdgeSelector.config.team_competitors;
        setResult(obj);
      }
    }
  }, [selectedRoundId])
  useEffect(() => {
    const roundId =
      stratEdgeSelector.result &&
      stratEdgeSelector.result.rounds &&
      stratEdgeSelector.result.rounds.find((r) => r.status === 0) &&
      stratEdgeSelector.result.rounds.find((r) => r.status === 0).id;


    const index = stratEdgeSelector?.result?.rounds?.findLastIndex((r) => r.status === 1);
    if (index !== -1)
      setCurrentRound(index + 1);


    setSelectedRoundId(roundId)
    if (
      stratEdgeSelector.config !== null &&
      stratEdgeSelector.result.round_scenarios &&
      stratEdgeSelector.result.round_scenarios.length > 0
    ) {
      let obj = stratEdgeSelector.result.round_scenarios.find(
        (rs) => rs.round_id === roundId
      );

      if (!obj) {
        obj =
          stratEdgeSelector.result.round_scenarios[
          stratEdgeSelector.result.round_scenarios &&
          stratEdgeSelector.result.round_scenarios.length - 1
          ];
      }

      if (obj) {
        obj.scenario_results.sort(function (a, b) {

          return (b.reserve) - (a.reserve);
        });
        obj.teamCompetitors = stratEdgeSelector.config.team_competitors;
        setResult(obj);
      }
    }
  }, [stratEdgeSelector.result, stratEdgeSelector.config]);
  useEffect(() => {
    setItemToReset(-1);
    setIsFound(-1);
    if (stratEdgeSelector.result.rounds) {
      const item1 = stratEdgeSelector.result.rounds.find(
        (item) => item.status === 0
      );
      if (!item1) {
        const item = stratEdgeSelector.result.rounds.find(
          (item) => item.status === -1
        );
        if (item) {
          setIsFound(item.id);
        }
        else {
          setIsFound(-1);
        }



        const item2 = stratEdgeSelector.result.rounds.findLast(
          (item) => item.status === 1
        );
        if (item2) setItemToReset(item2.id);

      }
    }
  }, [stratEdgeSelector.result]);

  return (
    <div>
      {stratEdgeSelector.loading && <Loader />}
      <DecisionsModal
        t={t}
        show={decisionsModalShow}
        onHide={() => {
          setDecisionsModalShow(false);
        }}
        selectedRoundId={selectedRoundId}
      />

      <h1 style={{ fontSize: 22 }}>{t("stratEdge.buisnessGame.results")} </h1>

      <div className="card-tour-container">
        {stratEdgeSelector.result &&
          stratEdgeSelector.result.rounds &&
          stratEdgeSelector.result.rounds.map((item, index) => {
            return (
              <RoundCard
                {...item}
                key={item.id}
                gameSessionId={currentSession.gameSessionId}
                enablePlay={isFound}
                dispatch={dispatch}
                onCLick={setSelectedRoundId}
                index={index}
                t={t}
                setCurrentRound={setCurrentRound}
                itemToReset={itemToReset}
                setItemToReset={setItemToReset}
                reload={reload}
              />
            );
          })}
      </div>

      <div className="card-badge-container">

        <ResultBusinessGame
          result={result}
          roundScenarios={stratEdgeSelector.result.round_scenarios}
          config={stratEdgeSelector.config}
          rounds={stratEdgeSelector.result.rounds}
          t={t}
          currentRound={currentRound}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            paddingRight: 30,
          }}
        >
          <Button
            style={{ backgroundColor: "#5B69BC" }}
            onClick={() => {
              setDecisionsModalShow(true);
            }}
          >
            <img
              alt=""
              src={BookOpen}
              // className="rounded-circle img-thumbnail avatar-lg adp-img"
              style={{
                width: 23,
                height: 18,
                marginRight: "7px",
              }}
            />
            {t("stratEdge.buisnessGame.decisions")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { StratEdgeAnalysis };
