import CryptoJS from "crypto-js";
import store from "../redux/store";
import queryString from 'query-string';
import { Cookies } from "react-cookie";


const parse = (number) =>
  number !== 0 ? (number >= 10 ? number : `0${number}`) : 0;

const toFixedOnlyFloat = (num) => {
  const dec = ('' + num).split('.')[1];

  if (dec) {
    return parseFloat(num).toFixed(2);
  } else {
    return num;
  }
};


const generatePVChallengeUrl = () => {

  const secretPass = process.env.REACT_APP_SECRET_PASS_PV_CHALLENG;
  const portPvChallenge = process.env.REACT_APP_PORT_PV_CHALLENG;

  const {token} =  store.store.getState().Auth.user;
  const {gameSessionId} =  store.store.getState().Module.module;

  const cookies = new Cookies();
  cookies.set("gameSessionId",gameSessionId ,  { path: '/' });


  let  token_ = CryptoJS.AES.encrypt(
      JSON.stringify({
        token,
        gameSessionId
      }),
      secretPass
  ).toString();

  const parmaUrlStringify = queryString.stringify({
    token:token_
  });

  if (process.env.NODE_ENV === "development") {

    return `http://localhost:${portPvChallenge}/pv-challenge`;
  }
  return `/pv-challenge/${token_}`;

}


const generateInsightsUrl = () => {

  const secretPass = process.env.REACT_APP_SECRET_PASS_PV_CHALLENG;
  const portInsight = process.env.REACT_APP_PORT_INSIGHT;
  const domainInsight = process.env.REACT_APP_DOMAIN_INSIGHT;

  const {token} =  store.store.getState().Auth.user;
  const {gameSessionId} =  store.store.getState().Module.module;

  const cookies = new Cookies();
  cookies.set("gameSessionId",gameSessionId ,  { path: '/' } );


  let  token_ = CryptoJS.AES.encrypt(
      JSON.stringify({
        token,
        gameSessionId,
        role:"participant"
      }),
      secretPass
  ).toString();

  const parmaUrlStringify = queryString.stringify({
    token:token_
  });

  if (process.env.NODE_ENV === "development") {
    return `http://localhost:${portInsight}/loaderPage?${parmaUrlStringify}`;
  }
  return `${domainInsight}/loaderPage?${parmaUrlStringify}`;

}


const generateLeadEdgeUrl = () => {

  const secretPass = process.env.REACT_APP_SECRET_PASS_PV_CHALLENG;
  const port = process.env.REACT_APP_PORT_LEADEDGE;
  const domain = process.env.REACT_APP_DOMAIN_LEADEDGE;

  const {user} =  store.store.getState().Auth;
  const {gameSessionId} =  store.store.getState().Module.module;

  

  let  token_ = CryptoJS.AES.encrypt(
      JSON.stringify({
        user,
        gameSessionId,
        role:"participant"
      }),
      secretPass
  ).toString();

  const parmaUrlStringify = queryString.stringify({
    token:token_
  });

  if (process.env.NODE_ENV === "development") {
    return `http://localhost:${port}/loaderPage/${parmaUrlStringify}`;
  }
  return `${domain}/loaderPage/${parmaUrlStringify}`;

}


const generatePvpharmaUrl = () => {

  const secretPass = process.env.REACT_APP_SECRET_PASS_PV_CHALLENG;
  const port = process.env.REACT_APP_PORT_PV_PHARMA;
  const domain = process.env.REACT_APP_DOMAIN_INSIGHT;

  const {token} =  store.store.getState().Auth.user;
  const {gameSessionId} =  store.store.getState().Module.module;

  const cookies = new Cookies();
  cookies.set("gameSessionId",gameSessionId ,  { path: '/' } );


  let  token_ = CryptoJS.AES.encrypt(
      JSON.stringify({
        token,
        gameSessionId,
        role:"participant"
      }),
      secretPass
  ).toString();

  const parmaUrlStringify = queryString.stringify({
    token:token_
  });

  if (process.env.NODE_ENV === "development") {
    return `http://localhost:${port}/loaderPage?${parmaUrlStringify}`;
  }
  return `${domain}/loaderPage?${parmaUrlStringify}`;

}


const getLinkGame = (gameName) => {
  switch (gameName) {
    case "StratEdge":
      return "/StratEdge";
    case "PV5Game":
      // return "/pv-pharma-5-game";
      return generatePvpharmaUrl();
    case "PVGame":
      return "/pv-game";
    case "FinEdge":
      return "/FinEdge";
    case "LeadEdge":
      return generateLeadEdgeUrl();
    case "PVChallenge":
      return generatePVChallengeUrl();
    case "Insights":
      return generateInsightsUrl();
    default:
      return "#";
  }
};

export {parse, toFixedOnlyFloat, getLinkGame};
